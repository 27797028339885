/*
 * @Description:
 * @Author: FCC
 * @Date: 2019-08-13 13:32:57
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-17 15:04:28
 */
import { generateMapByOpts } from 'common/utils'

// 订单状态
const orderStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  }
]

const orderStatusMap = generateMapByOpts(orderStatusOps)

// 支付状态
const isPayOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '已支付',
    value: 1
  },
  {
    text: '未支付',
    value: 0
  }
]

const isPayMap = generateMapByOpts(isPayOps)

// 订单类型
const payCategoryOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '个人',
    value: 0
  },
  {
    text: '组织',
    value: 1
  }
]

const payCategoryMap = generateMapByOpts(payCategoryOps)

// 优先处理
const firstDealOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '新订单',
    value: 1
  },
  {
    text: '15分钟未接收',
    value: 2
  },
  {
    text: '10天未处理',
    value: 3
  },
  {
    text: '20天未处理',
    value: 4
  },
  {
    text: '30天未处理',
    value: 5
  },
  {
    text: '10天及以上未处理',
    value: 6
  }
]

// 是否售后
const isAfterSalesIngOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

// 是否回访
const isReturnVisitOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '是',
    value: 2
  },
  {
    text: '否',
    value: 1
  }
]

// 加签类型
const isSignTypeOps = [
  {
    text: '全部',
    value: undefined
  }
]
// 处理状态
const operaOps = [
  {
    text: '已处理',
    value: 'done'
  },
  {
    text: '已关闭',
    value: 'close'
  }
]
// 提交按钮文字
const submitTextBackMap = {
  1: '接收，并返回列表',
  2: '保存，并返回列表',
  6: '开始处理，返回列表'
}
const submitTextNextMap = {
  1: '接收，并进行下一步',
  2: '保存，并进行下一步',
  6: '开始处理，并进行下一步'
}

// 所属服务的服务类型map
const subBackMap = {
  '投诉表扬': 0,
  '咨询建议': 1,
  '报修报事': 2
}

const orderNumberOps = [
  [
    {
      text: '全部',
      value: undefined
    },
    {
      text: '投诉',
      value: 1
    },
    {
      text: '表扬',
      value: 2
    }
  ],
  [
    {
      text: '全部',
      value: undefined
    },
    {
      text: '建议',
      value: 3
    },
    {
      text: '咨询',
      value: 4
    }
  ],
  [
    {
      text: '全部',
      value: undefined
    },
    {
      text: '居家维修',
      value: 1
    },
    {
      text: '园区维修',
      value: 2
    },
    {
      text: '园区保洁',
      value: 3
    },
    {
      text: '园区绿化',
      value: 4
    },
    {
      text: '园区安保',
      value: 5
    }
  ]
]
export {
  orderStatusOps,
  orderStatusMap,
  isPayOps,
  isPayMap,
  payCategoryOps,
  payCategoryMap,
  firstDealOps,
  isAfterSalesIngOps,
  isReturnVisitOps,
  isSignTypeOps,
  operaOps,
  submitTextBackMap,
  submitTextNextMap,
  subBackMap,
  orderNumberOps
}
