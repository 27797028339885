var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mianBox" },
    [
      _c("div", { staticClass: "mianLeft" }, [
        _c(
          "div",
          { staticClass: "tree-search" },
          [
            _c("v-input", {
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "请输入名称",
                width: 220,
              },
              model: {
                value: _vm.filterText,
                callback: function ($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "tree-wrap" }, [
          _c(
            "div",
            { staticClass: "tree-group_1001" },
            [
              _c("v-tree", {
                key: 1,
                attrs: {
                  data: _vm.companyTreeData,
                  defaultProps: _vm.companyTreeProps,
                  lazy: true,
                  "expand-on-click-node": false,
                  filterText: _vm.filterText,
                  filterNodeMethod: _vm.filterNodeMethod,
                  highlightCurrent: true,
                  expandOnClickNode: false,
                },
                on: { loadData: _vm.treeLoad, nodeClick: _vm.selectNode },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "mainRight" }, [
        _vm._m(0),
        _c("div", { staticClass: "companyName" }, [
          _vm._v(_vm._s(this.companyName)),
        ]),
        _vm.treeData.length > 0
          ? _c(
              "div",
              { staticClass: "tree_box" },
              [
                _c("el-tree", {
                  staticClass: "el_tree",
                  attrs: {
                    data: _vm.treeData,
                    "node-key": "id",
                    "default-expand-all": "",
                    draggable: false,
                    "allow-drop": _vm.allowDrop,
                    "allow-drag": _vm.allowDrag,
                    props: _vm.defaultProps_treeData,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ node, data }) {
                          return _c(
                            "span",
                            {
                              staticClass: "custom-tree-node",
                              on: {
                                mouseleave: function ($event) {
                                  return _vm.mouseleave(data, $event)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "label",
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.mouseover(data, $event)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(node.label))]
                              ),
                              _c(
                                "span",
                                { staticClass: "custom-tree-btn none" },
                                [
                                  _c(
                                    "v-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        permission: "addSameLevel",
                                      },
                                      on: {
                                        click: () =>
                                          _vm.clickDialogFormVisible_1(data),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              添加子分类\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-button",
                                    {
                                      staticStyle: { margin: "0px 20px" },
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        permission: "addChildLevel",
                                      },
                                      on: {
                                        click: () =>
                                          _vm.clickDialogFormVisible_2(data),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              添加同级分类\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        permission: "delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return (() =>
                                            _vm.clickRemove(node, data)).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              删除\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    2137227211
                  ),
                }),
              ],
              1
            )
          : _c("div", { staticClass: "empty_tree" }, [
              _c(
                "div",
                {
                  staticClass: "empty_tree_add",
                  on: { click: _vm.clickDialogFormVisible_3 },
                },
                [_vm._v("新增分类")]
              ),
              _c("div", { staticClass: "empty_tree_tips" }, [
                _vm._v("暂未分类"),
              ]),
            ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增分类", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "tips" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "inputBox" },
            [
              _c("div", { staticClass: "inputBox_tips" }, [
                _c("div", { staticClass: "inputBox_tips_icon" }, [_vm._v("*")]),
                _c("div", { staticClass: "inputBox_tips_text" }, [
                  _vm._v("分类名称"),
                ]),
              ]),
              _c("v-input", {
                staticClass: "inputBox_input",
                attrs: {
                  maxlength: 10,
                  width: 200,
                  placeholder: "请输入分类名称",
                },
                model: {
                  value: _vm.typeName,
                  callback: function ($$v) {
                    _vm.typeName = $$v
                  },
                  expression: "typeName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addRegionTree },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible_deleteFailed,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_deleteFailed = $event
            },
          },
        },
        [
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "当前项目内仍有该分类或子类工单未处理完成，请将工单处于已处理、已完成、已关闭状态后，再尝试关闭该分类"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_deleteFailed = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible_deleteSuccess,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_deleteSuccess = $event
            },
          },
        },
        [
          _c("span", { staticClass: "text" }, [
            _vm._v(
              "确定删除该分类？删除后，其子分类将一并删除。同时员工职责内对应的分类信息也将一并删除？"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_deleteSuccess = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.remove } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mainRight_tips" }, [
      _vm._v("\n      工单分类\n      "),
      _c("div", { staticClass: "mianRight_tsTips" }, [
        _vm._v("公司层级分类会自动同步至各项目内"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }