<template>
  <div class="serviceSheetForm-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      @update="update"
    >
      <col2-block title="工单流转">
        <!-- 进度 -->
        <col2-progress :status="progressList" :percent="progress" />
      </col2-block>
      <col2-block title="工单信息">
        <el-row>
          <col2-item  label="工单编号" :text="info.serialNumber" />
        </el-row>
        <col2-item  label="订购服务" :text="info.categoryName" />
        <col2-item  label="用户姓名" :text="info.userName" />
        <col2-item  label="工单来源" :text="info.source" />
        <col2-item  label="联系电话" :text="info.userMobile" />
        <col2-item  label="下单时间" :text="info.createTime" />
        <col2-item  label="用户地址" :text="info.taskInfoAddress" />
        <!-- <col2-item  label="预约时间" :text="'无'" /> -->
        <!-- <col2-item  label="服务商家" :text="info.shopName" /> -->
        <col2-item  label="工单状态">
          <span style="color:#a94442">{{orderStatusMap[info.status]}}</span>
        </col2-item>
        <!-- <col2-item  label="物流金额" :text="`${info.expensesMoney || '0.00'}元`" /> -->
        <!-- <col2-item  label="支付方式" :text="`${payTypeMap[info.payType] || ''}${info.payNo ? '(' + info.payNo + ')' : ''}`" /> -->
        <!-- <col2-item  label="工单类型" :text="`${info.payCategory === 0 ? '个人工单' : '组织工单(' + info.orgName + ')'}`" /> -->
        <!-- <col2-item  label="工单金额" :text="`${info.totalMoney || ''}元${info.subName && info.subName.indexOf('维修') !== -1 ? '(人工费' + info.laborFee + ',材料费' + info.materialFee + ')' : ''}`" /> -->
        <!-- <col2-item  label="支付状态" :text="`${info.payStatus === '0' ? '未支付' : '已支付'}`" /> -->
        <!-- <col2-item  label="支付时间" :text="info.onlinePaidTime" /> -->
        <!-- <col2-item v-if="info.payStatus !== '0'" label="应付金额" :text="`${info.orderMoney || ''}元`" /> -->
        <!-- <col2-item v-if="info.payStatus !== '0'" label="实付金额" :text="`${info.realMoney || ''}元`" /> -->
        <!-- <col2-item  label="抵价票券" :text="info.useTicketInfo" /> -->
        <!-- <col2-item  label="开票信息" :text="`${info.invoicStatus === 2 ? '已开票' : '未开票'}`" /> -->
      </col2-block>
      <!-- <col2-block title="收货人信息">
        <col2-item  label="收货人姓名" :text="consignee.processUserName" />
        <col2-item  label="收货人电话" :text="consignee.processUserMobile" />
        <col2-item  label="收货人地址" :text="consignee.userAddress" />
      </col2-block> -->
      <col2-block title="工单内容">
        <col2-item :span="24" label="工单图片">
           <v-preview :imgs="picturesList"></v-preview>
        </col2-item>
        <col2-item :span="24" label="工单视频" v-if="info.videos">
           <video controls :src="info.videos" width="300" height="300"></video>
        </col2-item>
        <col2-item :span="24" label="工单音频">
           <audio controls :src="item" v-for="(item, index) in info.audios" :key="index"></audio>
        </col2-item>
        <col2-item :span="24" label="工单描述" :text="info.description" />
      </col2-block>
      <col2-block title="处理情况"  v-if="false">
        <div></div>
        <el-form-item v-if="info.status == 5" label="处理后图片">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :imgUrls.sync="form.imageUrls"
            :limit="4"
          >
          </v-uploader>
        </el-form-item>
        <el-form-item v-if="afterHanderPic" label="处理后图片">
          <v-preview :imgs="afterPicturesList"></v-preview>
        </el-form-item>
        <el-form-item label="处理反馈" v-if="operationShow">
          <v-textarea v-model="form.operationDes" :maxlength="300"></v-textarea>
        </el-form-item>
        <el-form-item v-if="info.orderStatus == 2" label="处理状态">
          <v-select v-model="form.operaType" :options="operaOps"></v-select>
        </el-form-item>
      </col2-block>


      <!-- 点击完成处理后，显示收费信息，以及回访按钮 -->
      <col2-block class="chargeInfo" v-if="chargeInfo" title="收费信息">
        <div v-if="!isEditBill">
          <col2-item :span='24' label="收费">{{(isFee == 1 && !this.isDelete)?'有':'无'}} <v-button type="text" v-if="isEdit" style="margin-left:50px" @click="editBill">修改账单</v-button></col2-item>
          <div v-if="isFee == 1 && !this.isDelete">
            <col2-item label="材料费">
            {{'￥'+( materialCost?materialCost:'0')}}
            </col2-item>
            <col2-item label="人工费">
            {{'￥'+ (laborCost?laborCost:'0')}}
            </col2-item>
            <col2-item label="应收金额">
            {{'￥'+ (totalMoney?totalMoney:'0')}}
            </col2-item>
            <col2-item label="优惠金额">
            {{'￥'+ (discountMoney?discountMoney:'0')}}
            </col2-item>
            <col2-item label="实收金额">
            {{'￥'+ (paidMoney?paidMoney:'0')}}
            </col2-item>
            <col2-item label="支付状态">
            {{payStatus == 1?"已支付":"未支付"}}
            </col2-item>
            <col2-item label="收费账单">
              <v-button type="text"  @click="toChargeBillManageForm">点击查看</v-button>
            </col2-item>
            <col2-item label="订单编号">
              <span v-if="!orderSerialNumber">无</span>
              <v-button type="text" v-if="orderSerialNumber" @click="ChargeOrderMgrForm">{{orderSerialNumber}}</v-button>
            </col2-item>
          </div>
        </div>

        <col2-item v-if="isEditBill" label="收费情况"> 
          <div class="item">
            <span style="margin-right:20px">工单收费</span>
            <el-radio-group class="chargeName_name" v-model="ticketChargeRadio" @change="changeTicketChargeRadio">
              <el-radio label="1">有</el-radio>
              <el-radio label="2">无</el-radio>
            </el-radio-group>
          </div>
          <div v-if="ticketChargeRadio == 1">
            <div class="item">
              <v-select label="缴费人" placeholder="请选择缴费人" v-model="chargeOwner" :options="allOwnerOps"></v-select>
            </div>
            <div class="item">
              <v-input label="材料费" placeholder="请输入金额" v-model="materialCost" :maxlength='7'></v-input>
              <span style="margin-left:10px">元</span>
            </div>
            <div class="item">
              <v-input label="人工费" placeholder="请输入金额"  v-model="laborCost" :maxlength='7'></v-input>
              <span style="margin-left:10px">元</span>
            </div>
          </div>
          <span class="remark">注：管理端只能生成工单缴费账单，需物业上门出示收款二维码，业主扫码进行实际支付。</span>
          <v-button style="margin-right:5px" permission="orderDeal"  text="确认" @click="confirmEditTicket"></v-button>
          <v-button  text="取消" @click="cancelEditTicket"></v-button>
        </col2-item>

      </col2-block>
      
      <col2-block v-if="progressActionList.length > 0" title="最新工单进度">
        <div class="newProgress">
          <span>{{progressActionList[0].actionTime}}</span>
          <span>{{progressActionList[0].name}} : {{progressActionList[0].userName}} </span>
          <span v-if="progressActionList[0].tEvaluationInfoV">
            {{ progressActionList[0].tEvaluationInfoV.description }} 
          </span>
          <span v-else>{{ progressActionList[0].content }}</span>
          <a @click="showProgressDetail">>>点击查看详情</a>
        </div>
      </col2-block>
      <col2-block  title="用户评价" v-if="info.mEvaluationVList" >
        <div class="evaluate-wrapper" v-if="info.mEvaluationVList.length==0">用户尚未进行评价</div>
        <div class="evaluate-wrapper" v-for="(item, index) in info.mEvaluationVList" :key="index">
          <div class="title">用户评价#{{index+1}}</div>
          <div class="rate-row">
            <div class="col2" v-for="(subItem, subIndex) in item.evaluationItem" :key="subIndex">
              <span class="rate-label">{{subItem.name}}</span>
              <el-rate
                :value="subItem.value"
                disabled
                text-color="#ff9900"
                class="rate">
              </el-rate>
            </div>
          </div>
          <div class="eva-cell" v-if="item.description"><span class="label">评价内容:</span><span>{{item.description}}</span></div>
          <div class="eva-cell" v-if="item.evaluationTime"><span class="label">评价时间:</span><span>{{item.evaluationTime}}</span></div>
          <!-- <div class="eva-cell" v-if="item.reply"><span class="label">回复评价:</span><span>{{item.reply}}</span></div>
          <div class="eva-cell" v-if="item.replyTime"><span class="label">回复时间:</span><span>{{item.replyTime}}</span></div> -->
          <v-preview :imgs="item.imgs"></v-preview>
        </div>
        <div></div>
      </col2-block>
      <!-- 1、点击编辑进入详情页，显示处理反馈 以及 "开始处理" 按钮、"转派"按钮 
          工单处理 只能出现在 1、2、3、4、5状态
      -->
      <col2-block v-if="isEdit && info.status !== 6" title="工单处理">
        <col2-item  v-if="info.status==5" :span=50 label="图片反馈">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :imgUrls.sync="imgList"
            :limit="4"
          >
          <div slot="tip">注：建议宽300px高300px，图片小于5M</div>
        </v-uploader>
        </col2-item>
        <col2-item :span=20  label="处理反馈">
          <v-textarea v-model="form.feedback" :placeholder="defaultFeedback"></v-textarea>
        </col2-item>
        
        <!-- 2、点击"开始处理"按钮，变为 "完成处理"按钮 ，出现"收费情况"表单-->
        <col2-item  v-if="chargeCondition" label="收费情况"> 
          <div class="item">
            <span style="margin-right:20px">工单收费</span>
            <el-radio-group class="chargeName_name" v-model="ticketChargeRadio" @change="changeTicketChargeRadio">
              <el-radio label="1">有</el-radio>
              <el-radio label="2">无</el-radio>
            </el-radio-group>
          </div>
          <div v-if="ticketChargeRadio == 1">
            <div class="item">
              <v-select label="缴费人" placeholder="请选择缴费人" v-model="chargeOwner" :options="allOwnerOps"></v-select>
            </div>
            <div class="item">
              <v-input label="材料费" placeholder="请输入金额" v-model="materialCost" :maxlength='7'></v-input>
              <span style="margin-left:10px">元</span>
            </div>
            <div class="item">
              <v-input label="人工费" placeholder="请输入金额"  v-model="laborCost" :maxlength='7'></v-input>
              <span style="margin-left:10px">元</span>
            </div>
          </div>
          <span class="remark">注：管理端只能生成工单缴费账单，需物业上门出示收款二维码，业主扫码进行实际支付。</span>
        </col2-item>
      </col2-block>
      
      
      <!--工单状态：null 全部、1待接收、2待处理，3超时未接收、4超时未处理、5处理中、6已处理、7已完成、8已关闭 、9超时处理中
        只有进入编辑页面才会出现这两个按钮 -->
      <div v-if="isEdit" class="footerBtn">
        <!-- 1、"待接收阶段"、"超时待接收"  显示<接收>按钮、<转派>按钮 -->
        <v-button v-if="receiving" :width=74 text="接收" permission="receiving" @click="workOrderReceiving"></v-button>

        <!-- 2、"待处理阶段"、"超时待处理"  点击<接收>按钮进入，显示<开始处理>按钮、<转派>按钮 -->
        <v-button v-if="processing" :width=74 text="开始处理" permission="orderDeal" @click="workOrderProcessing"></v-button>
        <!-- 3、"处理中阶段"、"超时处理中"  显示<完成处理>按钮 -->
        <v-button v-if="finish" :width=74 text="完成处理" permission="orderDeal" @click="workOrderFinish"></v-button>
        
        <!-- <转派>按钮， 当处于"已处理、已完成、已关闭阶段"不显示该按钮，无法完成转派功能  -->
        <v-button v-if="transfer" permission="transfer" :width=74 text="转派" @click="toTransfer"></v-button>
        
        <!-- 4、"已处理阶段"  显示<回访>按钮 -->
        <v-button v-if="info.status==6 && !isRevisit"  :width=74 text="回访" permission="returnVisit" @click="revisit"></v-button>

        <!-- 关闭按钮 -->
        <v-button v-if="close"  :width=74 text="关闭" permission="close" @click="closeWorkOrder"></v-button>
      </div>
     
     
     <!-- <div v-if="canEdit" slot="footerSlot" class="footerBtn"> -->
        <!-- <v-button v-show='submitTextBack' type="success" :text="submitTextBack" @click="submitForm(0)"></v-button>
        <v-button v-show='submitTextNext' type="default" :text="submitTextNext" @click="submitForm(1)"></v-button>
        <v-button v-if='returnVisitShow' type="success" text="进行回访" @click="showReturnVisit"></v-button> -->
      <!-- </div> -->
    </form-panel>
    <multi-select
        :isShow.sync="isShow"
        :searchUrl="getCountersignListUrl"
        :headers="countersignTableHeader"
        :searchParams="searchParams"
        :responseParams="responseParams"
        :responseKey="responseKey"
        :backFill.sync="form.countersignTypeList">
        <template #searchSlot>
          <v-input label="标签名称" v-model="searchParams.labelName" />
          <v-select label="二级分类" v-model="searchParams.categoryId" :options="secondCategoryOps" />
        </template>
      </multi-select>
      <el-dialog
        title="工单进度"
        :visible.sync="dialogProgress"
        width="500px">
        <div>
          <el-timeline>
            <el-timeline-item class="pro-group" v-for="(item, index) in progressActionList" :key="index">
              <div class="progress">
                <div class="progress-top">
                  <span class="left">{{item.name}} : {{item.userName}}</span>
                  <span class="right">{{item.actionTime}}</span>
                </div>
                <div class="progress-remark" v-if="item.tEvaluationInfoV">{{item.tEvaluationInfoV.description}}</div>
                <div class="progress-remark" v-else>{{ item.content }}</div>
                <span class="progress-remark" v-if="item.workOrderStatus == 7 && item.content == ''">工单已完成</span>
                <span class="progress-remark" v-if="item.workOrderStatus == 8 && item.content == ''">工单已关闭</span>
                <div class="progress-view" v-if="item.tEvaluationInfoV">
                  <div class="star" v-for="(star,index) in JSON.parse(item.tEvaluationInfoV.evaluationItem)" :key="index">
                    <span>{{star.name}}：</span>
                    <el-rate  v-model="star.value" disabled></el-rate>
                  </div>
                  <v-preview :imgs="item.tEvaluationInfoV.images" :imgStyle="previewStyle"></v-preview>
                </div>
                <div class="progress-view" v-else>
                  <v-preview :imgs="item.images" :imgStyle="previewStyle"></v-preview>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-dialog>
      <el-dialog
        title="添加回访记录"
        :visible.sync="dialogRetuenVisit"
        width="600px">
        <div>
          <el-form label-width="120px" label-suffix="：">
            <el-form-item label="方式">
              <checkbox-plus ref="returnType" v-model="returnForm.type" :options="returnType" />
            </el-form-item>
            <el-form-item label="服务质量">
              <rate class="rates" v-model="returnForm.quality" text-color="#ff9900" />
            </el-form-item>
            <el-form-item label="服务速度">
              <rate class="rates" v-model="returnForm.speed" text-color="#ff9900" />
            </el-form-item>
            <el-form-item label-width="0">
              <v-textarea v-model="returnForm.content" placeholder="请填写回访结果" :maxlength="100"></v-textarea>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <v-button @click="submitReturnVisit" text="提 交"></v-button>
        </div>
      </el-dialog>
      <!-- 物流 begin -->
      <el-dialog
      title="物流信息"
      class="dialog"
      width="400px"
      :visible.sync="isExpressShow"
      >
      <el-form label-width="140px" label-suffix="：">
        <el-form-item label="快递单号">
          <v-input v-model="express.expNum"></v-input>
        </el-form-item>
        <el-form-item label="快递公司">
          <v-select2 ref="expCode" v-model="express.expCode" v-bind="expCodeParams" @onChange="expChange"></v-select2>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <v-button type="default" text="取 消" @click="isExpressShow=false"></v-button>
        <v-button @click="addExpress" text="确 定"></v-button>
      </div>
    </el-dialog>
    <el-dialog
      title="物流详情"
      class="dialog"
      width="600px"
      :visible.sync="isExpressInfoShow"
    >
      <div v-for="(item, index) in expressInfoList" :key="index" class="expBox">
        <span>{{item.ftime}}</span>
        <span>{{item.context}}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <v-button type="default" text="关 闭" @click="isExpressInfoShow=false"></v-button>
      </div>
    </el-dialog>
      <!-- 物流 end -->
      <!-- 工单转派 -->
      <el-dialog
        title="工单转派"
        :visible.sync="isTransfer"
        width="600px"
        >
        <div>
          <el-radio-group class="transferRadio" v-model="transferRadio">
            <el-radio :label="index" v-for="(item,index) in transferList" :key="item.id">
              <span>{{item.userName+'-'+item.phone}}</span>
              <span class="span1">{{item.processingCount}}个工单处理中</span>
            </el-radio>
          </el-radio-group>
          <el-pagination
            small
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total"
            @current-change="changeTransferList"
            :hide-on-single-page="isHideOnSinglePage">
          </el-pagination>
        </div>
        <div slot="footer">
          <el-button @click="isTransfer = false">取 消</el-button>
          <el-button type="primary" @click="transferGrant">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 工单回访 -->
      <el-dialog
        title="添加回访记录"
        :visible.sync="revisitVisible"
        width="700px"
        >

        <col2-block class="revisit">
          <col2-item class="item" :span=20 label="方式">
            <v-checkbox type="border" label="面访" :value="revisitType == 1" @change="changeRevisitType(1)"></v-checkbox>
            <v-checkbox type="border" label="电话" :value="revisitType == 2" @change="changeRevisitType(2)"></v-checkbox>
          </col2-item>
          <col2-item v-for="(item,index) in evaluationItem" :key="index" class="item" :span=20 :label="item.name">
            <el-rate v-model="item.value"></el-rate>
          </col2-item>
          <col2-item class="item">
            <v-textarea class="revisitText" v-model="revisitText" placeholder="请填写回访结果"></v-textarea>
          </col2-item>
        </col2-block>
        <div slot="footer">
          <!-- <el-button @click="revisitVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="workOrderRevisit">提交</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import {
  getDetailURL,
  saveURL,
  addCallbackInfoURL,
  getPayTypeListURL,
  queryTaskListURL,
  uploadURL,
  getOrderLabelListURL,
  getExpInfoURL,
  addExpressInfoURL,
  getViewOrderDetailInfoURL,
  getExpressDetailsURL,
  delExpURL,
  querySecondCategoryURL,
  queryLabelConfigURL,
  querySignTypeURL,
  queryServiceIDURL,
  getActionListApi,
  workOrderReceivingURL,
  workOrderProcessingURL,
  getAllOwnerByRoomIdURL,
  getTransferListURL,
  transferGrantURL,
  getWorkOrderChargeBillURL,
  genWorkOrderChargeBillURL,
  workOrderDealFinishURL,
  workOrderRevisitURL,
  updatePayStatusURL,
  workOrderCloseURL,
  getEvaluationItemListByIdsApi,
  deleteWorkOrderChargeBillURL
} from './api'
import { Col2Block, Col2Progress, Col2Item, TablePanel, MultiSelect, ChosenListPanel, CheckboxPlus } from '@/components/bussiness'
import { vPreview, vUploader } from 'components/control'
import { Rate, Row, Timeline, TimelineItem,Pagination} from 'element-ui'
import {
  operaOps,
  submitTextBackMap,
  submitTextNextMap,
  orderStatusMap,
  orderStatusOps,
  feedbackOps
} from './map'
import { generateMapByOpts } from 'common/utils'
import _ from 'lodash' // eslint-disable-line
import VCheckbox from '../../../components/control/v-checkbox/v-checkbox.vue'
export default {
  components: {
    Col2Block,
    Col2Progress,
    Col2Item,
    TablePanel,
    vPreview,
    vUploader,
    elRate:Rate,
    elRow: Row,
    MultiSelect,
    ChosenListPanel,
    elTimeline: Timeline,
    elTimelineItem: TimelineItem,
    CheckboxPlus,
    elPagination:Pagination,
    VCheckbox
  },
  data () {
    return {
      payStatus:'',
      isDelete:'',
      pageSize:20,
      changeSuccess:false,
      communityId:'',
      isPay:undefined,
      haveOrder:'',
      isFee:'',
      isEditBill:false,
      isRevisit:undefined,
      revisitText:'',
      revisitType:0,
      isCharge:'',
      userId:'',
      imgList:[],
      orderId:'',
      orderSerialNumber:'',
      chargeBillId:'',
      discountMoney:'',
      paidMoney:'',
      totalMoney:'',
      materialCost:'',
      laborCost:'',
      chargeOwner:undefined,
      allDataType:true,
      isHideOnSinglePage:true,
      transferVisible:'',
      processUserId:'',
      total:0,
      transferRadio:'',
      revisitVisible:false,
      isTransfer:false,
      ticketChargeRadio:undefined,
      sourceTypeMap: {},
      orderStatusMap,
      orderStatusOps,
      operaOps,
      uploadUrl: uploadURL,
      id: this.$route.query.id,
      orderCode: this.$route.query.orderCode,
      queryUrl: getDetailURL,
      closeType: ['', '', '用户取消工单', '工作人员关单', '外赠超时关单', '售后完成退款'],
      imgUrls: [],
      countersignTypeOps: [],
      canEdit: true,
      form: {
        operationDes: '您的工单已接收，稍后将安排工作人员为您服务。',
        caseId: this.$route.query.id,
        orderId: this.$route.query.id, // 新接口 工单字段
        operaType: 'done',
        cash: undefined,
        laborFee: undefined,
        materialFee: undefined,
        couponNum: undefined,
        couponValue: undefined,
        orderType: 0,
        orderStatus: undefined,
        evaluate: '',
        content1: undefined,
        content2: undefined,
        content3: undefined,
        content4: undefined,
        imageUrls: [],
        dataObject: this.$route.query.orderCode,
        taskMemo: '',
        countersignType: undefined,
        countersignTypeList: [],
        feedback:'',
        roomId:'',
        projectId:''
      },
      consignee: {},
      content: {},
      dealInfo: {},
      evaluateList: {},
      grantDetail: {},
      info: {},
      operation: {},
      orderNumber: '',
      subcode: '',
      payTypeOps: [],
      payTypeMap: {},
      getCountersignListUrl: getOrderLabelListURL,
      countersignTableHeader: [
        {
          prop: 'labelName',
          label: '标签名称'
        },
        {
          prop: 'category',
          label: '分类'
        }
      ],
      isShow: false,
      searchParams: {},
      responseParams: {
        id: 'id',
        name: 'labelName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      dialogProgress: false,
      progressDetail: [],
      previewStyle: {
        width: '60px',
        height: '60px',
        marginRight: '10px'
      },
      dialogRetuenVisit: false,
      returnType: [
        {
          label: '面访',
          value: 1
        }, {
          label: '电话',
          value: 2
        }
      ],
      returnForm: {
        quality: 5,
        speed: 5,
        content: '',
        type: 1
      },
      header: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      },
      // 工单内容 table begin
      expCodeParams: {
        searchUrl: getExpInfoURL,
        request: {
          text: 'expFirm',
          value: 'expCode'
        },
        response: {
          text: 'expFirm',
          value: 'expCode'
        }
      },
      express: {
        orderType: 2,
        orderId: '',
        orderDetailId: '',
        expNum: '',
        expCode: '',
        expFirm: ''
      },
      orderHeaders: [
        {
          prop: 'productName',
          label: '商品名称'
        },
        {
          prop: 'price',
          label: '单价'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'count',
          label: '购买数量'
        },
        {
          prop: 'shopName',
          label: '门店'
        },
        {
          prop: 'serviceStatus',
          label: '售后状态'
        },
        {
          prop: 'expInfo',
          label: '物流信息'
        },
        {
          prop: 'expMarkInfo',
          label: '物流动态'
        }
      ],
      isExpressShow: false,
      isExpressInfoShow: false,
      expressInfoList: [],
      // 工单内容 table end
      secondCategoryList: [],
      secondCategoryOps: [],
      isCountersignType: true,
      progressActionList: [],
      transferList:[],
      allOwnerOps:[],
      allOwnerList:[],
      evaluationItem:[]
    }
  },
  async created () {
    await this.$store.dispatch('mapStore/queryOrderSource')
    const { orderSourceMap } = this.$store.getters['mapStore/getOrderSource']()
    this.sourceTypeMap = orderSourceMap
  },
  computed: {
    progressList () {
      let ops = [
        {
          name: '下单时间',
          time: this.info.createTime
        },
        {
          name: '接单时间',
          time: this.operation.inceptTime
        },
        {
          name: '开始处理',
          time: this.operation.startDealTime
        },
        {
          name: '处理完成 ',
          time: this.operation.dealFinishTime
        },
        {
          name: this.operation.status == '8' ? '已关闭' : '已完成',
          time: this.operation.endDealTime
        }
      ]
      return ops
    },
    progress () {
      const progressMap = {
        1: 20,
        2: 40,
        5: 60,
        6: 80,
        7: 100,// 已完成
      }
      let status = this.info.status
      switch (status) {
          case 3: // 超时未接收 = 待接收   
            status = 1
            break;
          case 4: //  超时未处理 = 待处理
            status = 2
            break;
          case 9: //  超时处理中 = 处理中
            status = 5
            break;
          case 8: // 已完成
            status = 7
            break;
          default:
              break;
      }
      return progressMap[status]
      // return this.info.orderStatus ? progressMap[this.info.orderStatus] : 0
    },
    picturesList () {
      if (this.info.images) {
        if(this.info.images.indexOf('[') !== -1) {
          return JSON.parse(this.info.images)
        }else {
          return this.info.images.split("|")
        }
      }
      return []
    },
    afterPicturesList () {
      if (this.dealInfo.pictures && this.dealInfo.pictures.length > 0) {
        return this.dealInfo.pictures.map(img => img.url)
      } else {
        return []
      }
    },
    newProgress () {
      if (this.operation) {
        return {
          time: this.operation.dealTime,
          user: this.operation.dealerName,
          remark: this.operation.dealText
        }
      } else {
        return {}
      }
    },
    submitTextBack () {
      return submitTextBackMap[this.info.status]
    },
    submitTextNext () {
      return submitTextNextMap[this.info.status]
    },
    countersignTypeVisible () {
      // 只能待处理、处理中的工单能够加签，且已加签的工单不能再次加签
      return (Number(this.info.orderStatus) === 6 || Number(this.info.orderStatus) === 2) && this.info && (this.info.countersignTypeList && this.info.countersignTypeList.length < 1) && this.isCountersignType
    },
    laborFeeShow () {
      let result = this.info && this.info.orderStatus == 2 && this.info.subName.indexOf('维修') != -1 // eslint-disable-line
      return result
    },
    couponValueShow () {
      let result = this.info && this.info.payType == 0 && (this.subcode != 100 && this.subcode != 106 && this.subcode != 829) && this.info.orderStatus == 2 // eslint-disable-line
      return result
    },
    operationShow () {
      const numMap = [1, 2, 6]
      return numMap.indexOf(Number(this.info.orderStatus)) > -1
    },
    afterHanderPic () {
      const numMap = [3, 4, 5]
      return numMap.indexOf(Number(this.info.orderStatus)) > -1 && this.dealInfo && this.dealInfo.pictures && this.dealInfo.pictures.length > 0
    },
    evaluateShow () {
      const numMap = [4, 5]
      return numMap.indexOf(Number(this.info.orderStatus)) > -1
    },
    returnVisitShow () {
      let result = (this.info.orderStatus == 3 || this.info.orderStatus == 4) && this.info.isReturnVisit == 1 && this.info.userAddress && this.$store.getters.getPermission('returnVisit') // eslint-disable-line
      return result
    },
    cash () {
      return (this.form.laborFee || 0) + (this.form.materialFee || 0) - (this.form.couponValue || 0)
    },
    // 判断是查看还是编辑
    isEdit(){
      if(this.$route.query.isEdit && (this.info.status == 1 || this.info.status == 2 || this.info.status == 3 ||this.info.status == 4||this.info.status == 5||this.info.status == 6||this.info.status == 9)){
        return true
      }
    },
    /* 默认处理反馈 */
    defaultFeedback(){
      let status = this.info.status
      if(status == 1 || status == 3){
        return feedbackOps[0]
      }else if(status == 2 || status == 4){
        return feedbackOps[1]
      }else if(status == 5 || status == 9){
        return feedbackOps[2]
      }
    },
    chargeInfo(){
      if((this.info.status == 6 || this.info.status == 7 || this.info.status == 8) && this.isCharge === 1 && this.info.roomId){
        return true
      }
    },
    receiving(){
      return this.info.status==1 || this.info.status==3
    },
    processing(){
      return this.info.status==2 || this.info.status==4
    },
    finish(){
      return this.info.status==5 || this.info.status==9
    },
    transfer(){
      return this.transferVisible == 101 && this.info.status !==6 && this.info.status !== 7 && this.info.status !== 8
    },
    close(){
      return this.info.status==1 || this.info.status==2 || this.info.status==5 ||this.info.status==3 || this.info.status==4 || this.info.status==9
    },

    // isCharge 和 roomId控制 处理中状态 收费出现与否
    chargeCondition(){
      if((this.info.status==5 || this.info.status==9) && this.isCharge === 1 && this.info.roomId){
        return true
      }
    }
  },
  async mounted () {
    this.$setBreadcrumb('工单详情')
    this.id && this.$refs.form.getData({ tid: this.id})
    this.getPayTypeOps()
    this.searchParams.communityId = this.$route.query.communityId
    const { noEdit } = this.$route.query
    if (noEdit !== undefined) {
      // 代表不可编辑
      this.canEdit = false
    }
     // 控制转派按钮
    this.transferVisible = this.$store.state.userInfo.userType

    /* 获取账单信息 */
    await this.getWorkOrderChargeBill()
    // isDelete为1表示账单删除，是否收费按钮应为2
    if(this.isDelete){
      this.ticketChargeRadio='2'
    }
  },
  methods: {
    getPayTypeOps () {
      this.$axios.get(getPayTypeListURL).then(res => {
        if (res.status === 100) {
          const { data } = res
          let payTypeOps = data.map(item => {
            return {
              text: item.payTypeDesc,
              value: item.payType
            }
          })
          this.payTypeOps = payTypeOps
          this.payTypeMap = generateMapByOpts(payTypeOps)
        }
      })
    },
    update (data) {
      let { mevaluationVList, operation, serialNumber } = data
      this.evaluateList = mevaluationVList   // 评价
      this.info = data // 详情
      this.info.audios = data.audios.length > 0 ? data.audios.split('|') : []
      if(this.info.mEvaluationVList) {
        this.info.mEvaluationVList.forEach(item => {
          item.evaluationItem = JSON.parse(item.evaluationItem)
          item.imgs = item.imgs.length > 0 ? item.imgs.split('|') : []
          item.evaluationTime = item.evaluationTime.replace('T', ' ')
        }) 
      }
      this.operation = data
      this.orderNumber = serialNumber
      this.form.status = data.status
      this.processUserId = data.processUserId
      this.form.roomId = data.roomId
      this.form.projectId = data.projectId
      this.isCharge = data.isCharge
      this.isRevisit = data.isRevisit
      this.isFee = data.isFee
      this.userId = data.userId
      this.isPay = data.isPay
      this.categoryId = data.categoryId
      this.communityId = data.communityId
      if (data.status === '1') {
        this.form.operationDes = '您的工单已接收，稍后将安排工作人员为您服务。'
      } else if (data.status === '2') {
        this.form.operationDes = '您的工单已开始处理，请关注。'
      } else if (data.status === '5') {
        this.form.operationDes = '服务已完毕，请为本次服务打分/评价，祝生活幸福!'
      }
      // 查询二级分类
      // this.querySecondCategory(data.subcode)
      // this.searchParams.subCode = subcode
      // this.searchParams.orderNumber = orderNumber
      // this.queryLabelConfig(subcode, this.$route.query.communityId, orderNumber)
      // this.querySecondClassify()
      this.getActionList()
    },
    toSelectCountersignType () {
      this.isShow = true
    },
    submitForm (type) {
      let valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      let data = this.handleParams()
      let saveUrl = ''
      if (this.info.orderStatus === '1') {
        saveUrl = saveURL.reviceive
      } else if (this.info.orderStatus === '2') {
        saveUrl = saveURL.dispose
      } else if (this.info.orderStatus === '5') {
        saveUrl = this.form.operaType !== 'close' ? saveURL.finish : saveURL.close
      }
      this.$axios.put(saveUrl, this.$qs.stringify(data), this.header).then(res => {
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '保存成功',
            center: true
          })
          if (type === 0) {
            this.$router.go(-1)
          } else {
            this.$refs.form.getData({ orderId: this.id, orderType: 0 })
          }
        }
      })
    },
    handleParams () {
      let data = {}
      let keyArr = Object.keys(this.form)
      keyArr.forEach(value => {
        if (this.form[value] !== undefined) {
          data[value] = this.form[value]
        }
      })
      this.form.imageUrls.length > 1 && this.form.imageUrls.forEach((item, index) => {
        data[`content${index + 1}`] = item
      })
      data.imageUrls = JSON.stringify(this.form.imageUrls)
      if (this.countersignTypeVisible) {
        data.countersignType = JSON.stringify(this.form.countersignTypeList.map(item => item.id))
      }
      if (this.cash && this.laborFeeShow) {
        data.cash = Number(this.cash) < 0 ? 0 : this.cash
      }
      data.taskMemo = data.operationDes
      return data
    },
    showProgressDetail () {
      this.$axios.get(queryTaskListURL, { params: {
        caseId: this.id
      } }).then(res => {
        if (res.status === 100) {
          this.progressDetail = res.data ? res.data : []
          this.dialogProgress = true
        }
      })
    },
    showReturnVisit () {
      this.dialogRetuenVisit = true
    },
    submitReturnVisit () {
      let data = {
        caseId: this.id,
        // content: this.returnForm.content,
        serviceQuality: this.returnForm.quality,
        serviceRate: this.returnForm.speed,
        taskStatus: 3,
        type: this.returnForm.type,
        dataObject: this.id
      }
      if (this.returnForm.content) {
        data.content = this.returnForm.content
      }
      this.$axios.post(addCallbackInfoURL, data, this.header).then(res => {
        if (res.status === '100') {
          this.dialogRetuenVisit = false
          this.$message({
            type: 'success',
            message: '内容提交成功',
            center: true
          })
          this.$refs.form.getData({ orderId: this.id, orderType: 0 })
        }
      })
    },
    // 工单内容table begin
    handleContent (orderDetails) {
      orderDetails.forEach(item => {
        let expMarkInfo = ''
        if (item.expMark === '' && item.expFirmCode === '' && item.expS === '') {
          expMarkInfo= ''
        } else {
          expMarkInfo= `${item.expMark},${item.expFirmCode},${item.expS}`
        }
        item.expMarkInfo = {
          VNode: this.$createElement('a', {
            domProps: {
              innerHTML: expMarkInfo
            },
            style: {
              textDecoration: 'underline',
              cursor: 'pointer'
            },
            on: {
              click: () => {
                this.showExpressDetail(item)
              }
            }
          })
        }
      })
      return orderDetails
    },
    expChange (val) {
      if (val && val.expFirm) {
        this.express.expFirm = val.expFirm
      }
    },
    showExpress (row, opera) {
      this.isExpressShow = true
      this.express.orderId = this.orderId
      this.express.orderDetailId = row.orderDetailId
      if (opera === 'edit') {
        this.express.expNum = row.expFirmNo
        this.express.expCode = row.expFirmCode
        this.express.orderType = 0
      } else {
        this.express.expNum = ''
        this.express.expCode = ''
        this.$refs.expCode && this.$refs.expCode.clearValue()
        this.express.orderType = 1
      }
    },
    addExpress () {
      this.$axios.post(addExpressInfoURL, this.$qs.stringify(this.express), this.postHeader).then(res => {
        if (res.status === 100) {
          this.isExpressShow = false
          this.getViewOrderDetailInfo()
        }
      })
    },
    async delExpress (row) {
      let isOk = await this.$confirm('是否删除快递信息')
      if (!isOk) {
        return false
      }
      let data = {
        expFirm: '',
        expCode: '',
        expNo: '',
        id: row.orderDetailId,
        orderId: this.id
      }
      this.$axios.post(delExpURL, data, this.postHeader).then(res => {
        if (res.status === '100') {
          this.getViewOrderDetailInfo()
        }
      })
    },
    getViewOrderDetailInfo () {
      let params = {
        params: {
          orderId: this.id,
          orderType: 0
        }
      }
      this.$axios(getViewOrderDetailInfoURL, params).then(res => {
        if (res.status == 100) { // eslint-disable-line
          this.$set(this.content, 'products', this.handleContent(res.data))
        }
      })
    },
    showExpressDetail (item) {
      let params = {
        params: {
          orderId: this.id,
          expCode: item.expFirmCode,
          expNum: item.expFirmNo
        }
      }
      this.$axios.get(getExpressDetailsURL, params).then(res => {
        if (res.status === 100) {
          this.expressInfoList = res.data.expContext
          this.isExpressInfoShow = true
        }
      })
    },
    // 工单内容table end
    // 判断工单是否可以加签的接口
    async querySecondCategory (subCode) {
      let params = {
        subCode: subCode
      }
      let { data } = await this.$axios.get(querySecondCategoryURL, {
        params: params
      })
      if (data) {
        this.secondCategoryList = data
      }
    },
    // 查询是否能够加签
    async queryLabelConfig (subCode, communityId, orderNumber) {
      let { data } = await this.$axios.get(queryLabelConfigURL, {
        params: {
          subCode,
          communityId,
          orderNumber
        }
      })
      this.isCountersignType = data > 0
    },
    // 查询二级分类
    async querySecondClassify () {
      let res = await this.$axios.get(queryServiceIDURL)
      let { data } = await this.$axios.get(querySignTypeURL, { params: { parentId: res.data } })
      this.secondCategoryOps = data.map(item => ({ text: item.categoryName, value: item.id }))
      this.secondCategoryOps.unshift({
        text: '全部',
        value: undefined
      })
    },
    // 获取全部进度
    async getActionList() {
      let res = await this.$axios.get(getActionListApi, { params: { tid: this.info.id } })
      res.data.forEach(item => {
        let outImages = item.images
        item.images = outImages.length > 0 ? outImages.split('|') : []
        if(item.tEvaluationInfoV) {
          let images = item.tEvaluationInfoV.images
          item.tEvaluationInfoV.images = images.length > 0 ? images.split('|') : []
        }
      })

      this.progressActionList = res.data
    },
    showProgressDetail () {
      this.dialogProgress = true
    },
    /* 状态改变1、点击接单按钮 接单 状态由"待接收"1=>>"待处理"2 */
    workOrderReceiving(){
      this.changeWorkOrderStatus(workOrderReceivingURL,2,"接单")
    },
    /* 状态改变2、点击开始处理按钮 开始处理 状态由"待处理"2=>>"处理中"5 */
    workOrderProcessing(){
      this.changeWorkOrderStatus(workOrderProcessingURL,5,"处理")
    },
    /* 获取工单详情 */
    async getDetail(){
      await this.$axios.get(getDetailURL,{ params: { tid: this.info.id } }).then(res =>{
        this.update(res.data)
      })
    },
    async changeWorkOrderStatus(url,status,statusText){
      let data = {
        content:this.defaultFeedback,
        tid: this.info.id,
        status
      }
      if(this.form.feedback.trim() !== ''){
        data.content = this.form.feedback 
      }
      await this.$axios.post(url,data).then(res => {
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: statusText + '成功',
            center: true
          })
        }
      })
      this.form.feedback = ''
      /* 刷新页面数据*/
      this.getDetail()
    },

    /* 点击转派按钮 获取可转派人列表 */
    async toTransfer(_,curPage=1){
      this.isTransfer=true
      let data = {
        curPage,
        orderId:this.form.orderId,
        pageSize:this.pageSize,
        userId: this.$store.state.userInfo.id
      }
      let header = {
        headers:{
          defCommunityId:this.communityId
        }
      }
      await this.$axios.post(getTransferListURL,data,header).then(res => {
        if (res.status === 100) {
          this.transferList = res.data.resultList
          this.total = res.data.maxRow
        }
      })
    },
    // 修改分页器当前页更新可转派人列表
    changeTransferList(val){
      this.toTransfer(_,val)
    },
    /* 点击确认派单 */
    async transferGrant(){
      await this.$axios({
        url:transferGrantURL,
        method:'post',
        params:{
          receiveUserId:this.transferList[this.transferRadio].id,
          tid:this.info.id,
          userId:this.$store.state.userInfo.id
        }
      }).then(res => {
        if (res.status === 100) {
          this.$message({
            type:'success',
            message:'派单成功',
            center:true
          })
          this.isTransfer = false
          this.$router.push({path:'/home/orderSchedulingList'})  
        }
      })
    },


    /* 获取账单信息 */
    async getWorkOrderChargeBill(){
        await this.$axios.get(`${getWorkOrderChargeBillURL}/${this.id}`).then(res=>{
          if(res.status === 100 && res.data !== null){
            let {laborCost,materialCost,haveOrder,chargeBillId,orderId,discountMoney,paidMoney,totalMoney,serialNumber,isDelete,payStatus} = res.data
            this.laborCost = _.round((laborCost), 2).toFixed(2)
            this.materialCost = _.round((materialCost),2).toFixed(2)
            this.discountMoney = _.round((discountMoney),2).toFixed(2)
            this.paidMoney = _.round((paidMoney),2).toFixed(2)
            this.totalMoney = _.round((totalMoney),2).toFixed(2)
            this.ticketChargeRadio='1'
            this.haveOrder = haveOrder
            this.chargeBillId = chargeBillId
            this.orderId = orderId
            this.orderSerialNumber = serialNumber
            this.isDelete = isDelete
            this.payStatus = payStatus
          }
      })
    },
    /* 选择工单收费   缴费人下拉框查询该房号下所有用户 */
    async changeTicketChargeRadio(){
      if(!this.form.roomId && this.ticketChargeRadio === '1'){
        this.$message.error('获取不到缴费人列表，无法进行生成账单操作')
      }
      if(this.ticketChargeRadio === '1' && this.form.roomId){
        await this.$axios.get(`${getAllOwnerByRoomIdURL}?roomId=${this.form.roomId}`).then(res=>{
          if(res.status === 100){
            this.allOwnerOps = res.data.map((item,index)=>{
              return {
                text:`姓名:${item.userName}
                手机号:${item.mobileNum}`,
                value:index+1
              }
            })
            this.allOwnerList = res.data
          }
        })
        await this.getWorkOrderChargeBill()
      }
    },

    // 操作工单的账单
    async genWorkOrderChargeBill(){
      let reg1 = /^\d+$|^\d*\.\d+$/g
      let reg2 = /^\d+$|^\d*\.\d+$/g
      let data = {
        chargeUser:this.userId,
        materialCost:Number(this.materialCost),
        laborCost: Number(this.laborCost),
        roomId:this.form.roomId,
        subjectId:this.form.projectId,
        workOrderId:this.id
      }
      if(!this.materialCost) this.materialCost = 0
      if(!this.laborCost) this.laborCost = 0
      if((reg1.test(this.laborCost) && reg2.test(this.materialCost)) && this.chargeOwner){
        await this.$axios.post(genWorkOrderChargeBillURL,data).then(res=>{
          if(res.status === 100){
            this.changeSuccess = true
          }
        })
      }else{
        this.$message({
          type:'warning',
          message:'请重新填写收费情况',
          center:true
        })
        this.changeSuccess = false
      }
    },

    /* 状态改变3、 点击完成处理按钮 状态由"处理中"5=>>"已处理"6*/
    async workOrderFinish(){
      let img = this.imgList.join('|')
      let isFee = this.ticketChargeRadio === '1'?1:0
      let data = {
        content:this.defaultFeedback,
        tid: this.info.id,
        status:6,
        isFee,
        img
      }
      if(this.form.feedback.trim() !== ''){
        data.content = this.form.feedback 
      }
      // 修改账单
      if(this.ticketChargeRadio === '1'){
        await this.genWorkOrderChargeBill()
      }
      if(!this.ticketChargeRadio && this.isCharge){
        this.$message({
          type:'warning',
          message:'请选择是否收费',
          center:true
        })
        return
      }

      if(this.changeSuccess || this.ticketChargeRadio === '2' || !this.ticketChargeRadio){

        await this.$axios.post(workOrderDealFinishURL,data).then(res=>{
          if(res.status === 100) {
            this.$message({
              type:'success',
              message:'已完成处理',
              center:true
            })
          }
        })
        /* 刷新页面数据*/
        this.form.feedback = ''
        this.getWorkOrderChargeBill()
        this.getDetail()
      }
    },

    /* 点击回访按钮 */
    async revisit(){
      this.revisitVisible = true
      let header = {
        headers:{
          defCommunityId:this.communityId
        }
      }
      await this.$axios.get(`${getEvaluationItemListByIdsApi}?categoryId=${this.categoryId}`,header).then((res) => {
          if(res.status === 100){
              this.evaluationItem =  res.data.map(item => {
                  Reflect.deleteProperty(item, 'id')
                  Reflect.set(item, 'value', 0)
                  return item
              })
          }
      })
    },
    /* 控制 revisitType 互斥，回访方式*/
    changeRevisitType(val){
      if(this.revisitType == 0){
        this.revisitType = val
      }else if(this.revisitType != 0 && this.revisitType != val){
        this.revisitType = val
      }else if(this.revisitType == val){
        this.revisitType = 0
      }
    },

    /* 点击提交回访按钮 */
    async workOrderRevisit(){
      if(this.revisitType == 0){
        this.$message({
          type:'warning',
          message:'请选择回访方式'
        })
        return
      }
      if(this.evaluationItem.length==0 || this.evaluationItem.find(item=>item.value == 0)){
        this.$message({
          type:'warning',
          message:'请对回访信息进行打分'
        })
        return
      }
      let visitMethod = this.revisitType - 1
      let data = {
        taskId:this.info.id,
        visitMethod,
        desc:this.revisitText,
        evaluationItem:this.evaluationItem
      }
      await this.$axios.post(workOrderRevisitURL,data).then(res=>{
        if(res.status === 100){
          this.$message({
            type: 'success',
            message: '新增回访成功',
            center: true
          })
          this.revisitVisible = false
        }
      })
      /* 刷新页面数据*/
      this.getDetail()
    },


    /* 点击修改账单 */
    async editBill(){
      if(this.haveOrder){
        this.$message({
          type:'warning',
          message:'订单已生成，无法修改账单',
          center:true
        })
        return
      }
      if(!this.form.roomId){
        this.$message.error('未查询到房号，无法进行生成账单操作')
        return
      }
      this.isEditBill = true
      /* 获取账单信息 */
      await this.getWorkOrderChargeBill()
      // isDelete为1表示账单删除，是否收费按钮应为2
      if(this.isDelete){
        this.ticketChargeRadio='2'
      }
      await this.$axios.get(`${getAllOwnerByRoomIdURL}?roomId=${this.form.roomId}`).then(res=>{
          if(res.status === 100){
            this.allOwnerOps = res.data.map((item,index)=>{
              return {
                text:`姓名:${item.userName}
                手机号:${item.mobileNum}`,
                value:index+1
              }
            })
            this.allOwnerList = res.data
            this.chargeOwner = this.allOwnerList.findIndex(item=>item.userId === this.userId) + 1
          }
        })
    },
    /* 确认修改账单 */
    async confirmEditTicket(){
      let chargeStatus = this.ticketChargeRadio == 1?1:0
      let header = {
        headers:{
          defCommunityId:this.communityId
        }
      }
      // 判断订单是否存在，存在就无法修改账单
      if(!this.haveOrder){
        if(chargeStatus){
          await this.genWorkOrderChargeBill()
        }
        if(this.changeSuccess || chargeStatus === 0){
          if(this.ticketChargeRadio === '2'){
            await this.$axios.delete(`${deleteWorkOrderChargeBillURL}/${this.id}`)
          }
          await this.$axios.post(updatePayStatusURL,{chargeStatus,id:this.info.id},header)
          this.isEditBill = false
          /* 刷新页面数据*/
          await this.getDetail()
          await this.getWorkOrderChargeBill()
          if(this.isDelete){
            this.ticketChargeRadio='2'
          }
        }
      }else{
        this.$message({
          type:'warning',
          message:'订单已生成，无法修改该账单',
          center:true
        })
      }
    },
    /* 取消修改账单 */
    async cancelEditTicket(){
      this.isEditBill = false
        this.ticketChargeRadio = this.isFee?'1':'2'
        await this.getWorkOrderChargeBill()
        // isDelete为1表示账单删除，是否收费按钮应为2
        if(this.isDelete){
          this.ticketChargeRadio='2'
        }
    },

    /* 关闭工单 */
    async closeWorkOrder(){
      let data = {
        content:'亲爱的业主，您提交的工单已被关闭。如有疑问，请随时联系物业服务中心。',
        isFee:this.isFee,
        status:8,
        tid:this.info.id
      }
      await this.$axios.post(workOrderCloseURL,data).then(res=>{
            if(res.status === 100){
              this.$message({
                type:'success',
                message:'工单已关闭',
                center:true
              })
            }
      })
      /* 刷新页面数据*/
      this.getDetail()
    },
    /* 点击查看收费账单跳转到对应的账单详情页 */
    toChargeBillManageForm(){
      this.$router.push({
        name: 'chargeBillManageForm',
        query: {
          id: this.chargeBillId,
          communityId: this.communityId,
          // chargeBill: 1,
          listGroupType:2
        }
      })
    },
    /* 点击订单编号跳转到对应的订单详情页 */
    ChargeOrderMgrForm(){
      this.$router.push({
        name: 'chargeOrderMgrForm',
        query: {
          id: this.orderId
          // id: 117456
        }
      })
    }
  }
  
}
</script>
<style lang="scss" scoped>
.newProgress {
  padding-left: 168px;
  span {
    margin-right: 30px;
  }
  a {
    cursor: pointer;
    color: #337ab7;
  }
}
.footerBtn {
  padding-left: 168px;
  .v-control {
    margin-right: 15px;
  }
  .v-control:last-child {
    margin-right: 0;
  }
}
.progress {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-left: -23px;
  padding-left: 23px;
  .progress-top {
    display: flex;
    justify-content: space-between;
    line-height: 28px;
    border-bottom: 1px solid #ccc;

    .left {
      color: #1b8cf2;
    }
    .right {

    }
  }
  .progress-remark {
    margin: 15px 0;
  }
  .progress-view {

  }
}
.pro-group:last-child {
  .progress {
    border: none;
  }
}
.rates {
  text-align: left;
  margin-top: 10px;
}
.serviceSheetForm-container {
  .table-wrapper {
    width: 100%;
    margin-left: 85px;
  }
  .evaluate-wrapper {
    padding-left: 168px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 10px;
      font-weight: 700;
    }
    .rate-row {
      display: flex;
      .col2 {
        display: flex;
        flex: 1;
        .rate-label {
          width: 70px;
        }
      }
    }
    .eva-cell {
      display: flex;
      margin: 10px 0;

      .label {
        width: 70px;
      }
    }
  }
}
.evaluate-panel {
  padding-left: 168px;
  margin-bottom: 10px;
  .title {
    margin-bottom: 10px;
    font-weight: 700;
  }
  .rate-row {
    display: flex;
    .col2 {
      display: flex;
      flex: 1;
      .rate-label {
        width: 70px;
      }
    }
  }
  .eva-cell {
     display: flex;
     margin: 5px 0;
    .label {
      width: 70px;
    }
    .text {

    }
  }
}
.newProgress {
  padding-left: 168px;
  span {
    margin-right: 30px;
  }
  a {
    cursor: pointer;
    color: #337ab7;
  }
}
.item{
  padding-bottom: 10px;
  ::v-deep label {
    margin-right: 20px;
  }
  ::v-deep .select2-text{
    margin-right: 20px;
  }
}
.remark{
  font-size: 14px;
  color: #606266;
}
.transferRadio{
  display: flex;
  flex-direction:column;
  text-align: left;
  ::v-deep .el-radio{
    margin:0 0 10px 0;
    .el-radio__label{
      .span1{
        position: absolute;
        right: 0;
      }
    }
  }
}
.revisit{
  ::v-deep .text{
    display: flex;
    .checkbox-wrapper{
      margin-right: 10px;
    }
  }
  .item{
    margin-bottom: 15px;
    .revisitText{
      margin-left: 85px;
    }
  }
}
.chargeInfo{
  a {
    cursor: pointer;
    color: #337ab7;
    margin-right: 10px;
  }
}
.star{
  display: flex;
}
</style>
