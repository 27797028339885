<template>
  <div class="mianBox">
    <!-- 工单分类页面哦 -->
    <div class="mianLeft">
      <!-- 搜索框 -->
      <div class="tree-search">
        <v-input
          v-model="filterText"
          prefix-icon="el-icon-search"
          placeholder="请输入名称"
          :width="220"
        />
      </div>

      <!-- 控件树 -->
      <div class="tree-wrap">
        <div class="tree-group_1001">
          <v-tree
            :data="companyTreeData"
            :defaultProps="companyTreeProps"
            :lazy="true"
            :expand-on-click-node="false"
            :filterText="filterText"
            :filterNodeMethod="filterNodeMethod"
            :highlightCurrent="true"
            :expandOnClickNode="false"
            @loadData="treeLoad"
            @nodeClick="selectNode"
            :key="1"
          ></v-tree>
        </div>
      </div>
    </div>

    <div class="mainRight">
      <div class="mainRight_tips" >
        工单分类
        <div class="mianRight_tsTips">公司层级分类会自动同步至各项目内</div>
      </div>

      <div class="companyName">{{this.companyName}}</div>

      <div class="tree_box" v-if="treeData.length > 0">
        <el-tree
          class="el_tree"
          :data="treeData"
          node-key="id"
          default-expand-all
          :draggable='false' 
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          :props='defaultProps_treeData'
        >
        <!-- 暂不提供拖拽功能，暂无接口 -->
          <span class="custom-tree-node" slot-scope="{ node, data }"  @mouseleave=mouseleave(data,$event)>
            <span class="label"  @mouseover=mouseover(data,$event) >{{ node.label }}</span>
            <span class="custom-tree-btn none" >
              <v-button
                type="text"
                size="mini"
                permission="addSameLevel"
                @click="() => clickDialogFormVisible_1(data)"
              >
                添加子分类
              </v-button>
              <v-button
                style="margin: 0px 20px"
                type="text"
                size="mini"
                permission="addChildLevel"
                @click="() => clickDialogFormVisible_2(data)">
                添加同级分类
              </v-button>
              <v-button
                type="text"
                size="mini"
                permission="delete"
                @click.stop="() => clickRemove(node, data)">
                删除
              </v-button>
            </span>
          </span>
        </el-tree>
      </div>
      <!-- treeData.length > 0 -->
      <div class="empty_tree" v-else>
        <div class="empty_tree_add" @click="clickDialogFormVisible_3">新增分类</div>
        <div class="empty_tree_tips">暂未分类</div>
      </div>
    </div>

    <el-dialog title="新增分类" :visible.sync="dialogFormVisible">
      <div class="tips">基本信息</div>
      <div class="inputBox">
        <div class="inputBox_tips">
          <div class="inputBox_tips_icon">*</div>
          <div class="inputBox_tips_text" >分类名称</div>
        </div>

        <v-input v-model="typeName" :maxlength='10' :width='200' placeholder="请输入分类名称" class="inputBox_input" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addRegionTree">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible_deleteFailed"
      width="30%"
    >
      <span class="text">当前项目内仍有该分类或子类工单未处理完成，请将工单处于已处理、已完成、已关闭状态后，再尝试关闭该分类</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible_deleteFailed = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible_deleteSuccess"
      width="30%"
    >
      <span class="text">确定删除该分类？删除后，其子分类将一并删除。同时员工职责内对应的分类信息也将一并删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_deleteSuccess = false">取 消</el-button>
        <el-button type="primary" @click="remove">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRegionTreeURL, getTreeByRegionIdURL, addRegionTreeURL ,preDeleteURL ,deleteRegionTreeURL } from "./api";
import {} from "./map";
import { vTree, vInput } from "components/control/index";
import { Tree } from "element-ui";
export default {
  name: "OrderSchedulingClassificationList",
  components: {
    vTree,
    "v-input": vInput,
    "el-tree": Tree
  },
  data() {
    let _this = this;
    return {
      companyName: '',
      companyTreeData: [],
      companyTreeProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data, node) => {
          return false
        }
      },
      filterText: "",
      treeData: [],
      treeProps: {
        children: "children",
        label: "orgName",
        isLeaf: (data, node) => {
          return true;
        },
      },
      defaultProps: {
        children: "children",
        label: "label"
      },
      dialogFormVisible: false,
      typeName: '',
      regionId: 0,
      nodeLevel: 0,
      appendData: '',
      appendDataFlag: 1,  // 判断同级添加还是子级添加的标识
      fristFlg: true, // 第一次进入页面 没有刷新标志
      defaultProps_treeData: {
        label: "name",
        children: 'childrenList'
      },
      selectNodeData: {}, // 选中的节点数据
      dialogVisible_deleteFailed: false,
      dialogVisible_deleteSuccess: false,
      deleteData: null, // 删除节点数据
    };
  },
  async created() {},
  computed: {},
  mounted() {},
  methods: {
    // tree 控件的操作方法 - start
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === '二级 3-1') {
        return type !== 'inner';
      } else {
        return true;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf('三级 3-2-2') === -1;
    },
    // tree 控件的操作方法 - End
    mouseleave(data,$event){
      $event.currentTarget.firstElementChild.nextElementSibling.setAttribute('class','none')
    },
    mouseover(data,$event){
      $event.currentTarget.nextElementSibling.setAttribute('class','block');
    },
    // 获取左侧公司列表 方法
    getProjectTree (level, id) {
      let params = {
        nodeLevel: level
      }
      id && (params.id = id)
      if(level !== 0) {
        return new Promise((resolve, reject) => {
          this.$axios.get(getRegionTreeURL, { params }).then(res => {
            if (res.status === 100) {
              let { data } = res
              resolve(data)
            }
          })
        })
      }
    },
    async treeLoad (node, resolve) {
      let { nodeLevel, id } = node.data;
      let list = await this.getProjectTree(nodeLevel, id)
      console.log(list, 'list');
      if(this.fristFlg) {
        const { name, orgId, nodeLevel } = list[0];

        this.companyName = name;
        this.regionId = orgId;
        this.nodeLevel = nodeLevel;
        this.fristFlg = false;
      }

      setTimeout(() => {
        return resolve(list)
      }, 500)
    },
    // 选中公司的某个节点之后 获取对应的工单数据
    selectNode (data) {
      let { name, orgId } = data

      console.log(data, 'data');
      this.selectNodeData = data;
      this.companyName = name;
      this.regionId = orgId;
      // getTreeByRegionIdURL
      let params = {
        regionId: orgId
      }

      return new Promise((resolve, reject) => {
        this.$axios.get(getTreeByRegionIdURL, { params }).then(res => {
          console.log(res, 'res')
          if (res.status === 100) {
            let { data } = res
            // resolve(data)

            this.treeData = data
          }
        })
      })

      // this.$nextTick(() => {
      //   if (nodeLevel === 0) {
      //     this.$set(this.searchParams, 'name', name)
      //     this.$refs.list.searchData()
      //   }
      // })
    },
    filterNodeMethod(value, data) {
      // 搜索时 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      return data[this.companyTreeProps.label].indexOf(value) !== -1;
    },
    // 添加子分类的方法
    clickDialogFormVisible_1(data) {
      this.appendData = data;
      this.appendDataFlag = 1;
      this.dialogFormVisible = true;
      console.log('子分类--data', data)
    },
    // 添加同级分类的方法
    clickDialogFormVisible_2(data) {
      this.appendData = data;
      this.appendDataFlag = 2;
      this.dialogFormVisible = true;
      console.log('同级 分类--data', data)
    },
    // 无分类情况下新增分类方法
    clickDialogFormVisible_3() {
      this.appendData = null;
      this.appendDataFlag = 3;
      this.dialogFormVisible = true;
    },
    // 添加节点方法
    addRegionTree() {
      if(!this.typeName) {
        this.$message.error('添加分类名称为空');
      } else {
        let data = this.appendData;
        let nodeLevel = '';
        let _this = this;
        if(this.appendDataFlag === 1) {
          // 添加子分类
          nodeLevel = data.id;
        } else if(this.appendDataFlag === 2){
          // 添加同级分类
          nodeLevel = data.parentId;
        } else {
          // 没有分类第一次添加的时候
          nodeLevel='0'
        }
        console.log('this.regionId', this.regionId)
        this.$axios({
          url: `${addRegionTreeURL}?parentId=${nodeLevel}&regionId=${this.regionId}&typeName=${this.typeName}`,
          method: 'post'
        }).then( res => {
          if(res.data) {
            this.$message({
              message: '添加分类成功',
              type: 'success'
            });

            _this.typeName = '';
            if(_this.selectNodeData.orgId) {
              _this.selectNode(_this.selectNodeData);
            } else {
              _this.selectNode({
                name: this.companyName,
                orgId: this.regionId
              })
            }
          }

          this.dialogFormVisible = false;
        }).catch(res => {
          this.$message.error('添加分类失败');
          this.dialogFormVisible = false;
        })
      }
    },
    // 点击删除节点的方法
    async clickRemove(node, data) {
      // const parent = node.parent;
      // const children = parent.data.children || parent.data;
      // const index = children.findIndex(d => d.id === data.id);
      // children.splice(index, 1);

      // 20220215 新增判断当前节点是否存在子节点 存在子节点的的节点不能被删除
      if(node.childNodes && node.childNodes.length > 0) {
        // 存在子节点 不能被删除  删除失败
        console.log('删除失败')
        this.$message.error('请先删除当前分类下的所有子分类');
      } else {
        // 不存在子节点 进行判断是否存在未完成工单
        let res = await this.preDelete(data);
        if(res.data) {
          console.log('删除成功')
          this.deleteData =  data;
          this.dialogVisible_deleteSuccess = true;
        } else {
          console.log('删除失败')
          this.dialogVisible_deleteFailed = true;
        }
      }
      console.log('node', node)
      console.log('data', data)
    },
    // 判断该节点是否可以删除的函数
    preDelete(data) {
      let _this = this;
      let params = {
        regionId: this.regionId,
        typeId: data.id
      }
      return new Promise((resolve, reject) => {
        this.$axios.get(preDeleteURL, { params })
        .then( res => {
          if(res) {
            resolve(res)
          }
          // console.log(res, '++res++')
        }).catch(res => {
          this.$message.error('删除分类失败');
        })
      })
    },
    // 删除当前节点
    remove() {
      let _this = this;
      // let params = {
      //   regionId: _this.regionId,
      //   typeId: _this.deleteData.id
      // }
      // this.$axios.get( deleteRegionTreeURL, { params })
      // .then( res => {
      //   if(res.data) {
      //     this.$message({
      //       message: '删除分类成功',
      //       type: 'success'
      //     });
      //   }
      //   // console.log(res, '++res++')
      // }).catch(res => {
      //   this.$message.error('删除分类失败');
      // })

      this.$axios({
        url: `${deleteRegionTreeURL}?regionId=${_this.regionId}&typeId=${_this.deleteData.id}`,
        method: 'post'
      }).then( res => {
        if(res.data) {
          this.$message({
            message: '删除分类成功',
            type: 'success'
          });
          _this.dialogVisible_deleteSuccess = false;
          _this.selectNode(_this.selectNodeData);
        }

        // this.dialogFormVisible = false;
      }).catch(res => {
        this.$message.error('删除分类失败：' + res.msg);
        _this.dialogVisible_deleteSuccess = false;
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.mianBox {
  width: 100%;
  display: flex;

  .mianLeft {
    padding: 0 20px;
    box-sizing: border-box;

    .tree-wrap {
      margin-top: 20px;
      height: calc(100% - 93px);
      width: 240px;

      .tree-group_1001 {
        width: 100%;
        height: 100%;
        padding-right: 20px;
        overflow: auto;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }

  .mainRight {
    display: flex;
    flex: 1;
    flex-direction: column;

    &_tips {
      display: flex;
      font-size: 24px;
      color: #333;
      width: 100%;
      align-items: baseline;

      .mianRight_tsTips {
        font-size: 14px;
        color: #B2B2B2;
        margin-left: 20px;
      }
    }

    .companyName {
      width: 100%;
      font-size: 18px;
      color: #333;
      text-align: left;
      font-weight: 400;
      margin: 32px 0;
    }

    .tree_box {
      padding-left: 340px;
      overflow: auto;
    }

    .tree_box::after {
      content: '';
      display: block;
      width: 100%;
      height: 60px;
    } 

    .empty_tree {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .empty_tree_add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 40px;
        background-color: rgba(22, 155, 213, 1);
        border-radius: 5px;
        font-weight: 400;
        color: #FFFFFF;
        font-size: 13px;
      }

      .empty_tree_tips {
        margin-top: 16px;
        font-size: 13px;
        color: #333;
      }
    }
  }

  .tips {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    color: #333;
  }

  .inputBox {
    display: flex;
    width: 100%;
    align-items: center;

    .inputBox_tips {
      display: flex;
      align-items: center;
      margin: 0 30px;

      .inputBox_tips_icon {
        font-size: 14px;
        color: #FF0101;
      }

      .inputBox_tips_text {
        color: #606266;
        font-size: 14px;
      }
    }
  }
}
.custom-tree-node {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .custom-tree-btn {
    opacity: 0;
  }

  .label {
    display: inline-block;
    padding: 0px 20px;
  }

  // .custom-tree-btn:hover {
  //   opacity: 1;
  // }
}
.custom-tree-node:hover {
  opacity: 1 !important;
}

.text {
  text-align: left;
  display: inline-block;
}

.none {
  opacity: 0;
  display: none;
}

.block {
  opacity: 1;
  display: block;
}

.tree-group_1001 {
  ::v-deep .tree-wrapper>.el-tree>.el-tree-node>.el-tree-node__content>.el-tree-node__expand-icon {
    display: none !important;
  }
} 
</style>
